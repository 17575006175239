.homepage-container{
  width: 100%;
}
.section-b{
  width: 100%;
  &.v-1{
    margin-bottom: 100px;
  }
  &.v-2{
    margin-bottom: 130px;
  }
  &.v-5{
    margin-bottom: 80px;
    padding-top: 180px;
  }
  &.v-6{
    margin-bottom: 180px;
  }
  &.v-7{
    margin-bottom: 80px;
  }
  &.v-8{
    padding: 0 168px;
    margin-bottom: 80px;
  }
}

//Section v1 start---------------
.home-main-section{
  width: 100%;
  display: grid;
  grid-template-columns: 7fr 5fr;
  min-height: 700px;
}
.left-t-col{
  padding: 50px 50px 50px 168px;
  position: relative;
  z-index: 20;
}
.top-banner-text-b{
  width: 100%;
  margin-bottom: 24px;
  > .title-text-v-1{
    margin-bottom: 24px;
  }
}
.download-app-section{
  min-height: 165px;
  width: 784px;
  border-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 26px 34px -13px rgba(198,198,198,0.35);
  padding: 24px;
}
.mini-b-title{
  width: 100%;
  text-align: center;
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 22px;
}
.app-button-row{
  border-radius: 6px;
  display: flex;
  justify-content: center;
}
.app-button-v{
  padding: 0 24px 0 0;
  height: 62px;
  box-shadow: 0 0 0 1px #0AA0FF inset;
  color: #000000;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  margin: 0 16px;
  cursor: pointer;
}
.app-icon-v{
  width: 70px;
  flex: 1;
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 34px;
}
.app-name-v{
  display: inline-flex;
  height: 100%;
  white-space: nowrap;
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  align-items: center;
}
.private-club-b{
  width: 100%;
  text-align: center;
  padding: 40px 0 0;
  margin-bottom: 20px;
  > .btn-v{
    box-shadow: 0 16px 34px -13px rgba(0,57,124,0.35);
    &.big{
      min-width: 256px;
      max-width: 256px;
    }
  }
}
.play-b-title-t{
  width: 100%;
  color: #9B9B9B;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}
.right-img-col{
  position: relative;
}
.img-t-b{
  width: 800px;
  position: absolute;
  top: -70px;
  right: 0;
  height: 700px;
  padding-bottom: 100%;
  background: url("../../images/shutterstock_650951440@2x.png") no-repeat 0 0;
  background-size: contain;
  z-index: 3;
}
//Section v1 END-----------------

//Section v2 start-----------------
.b-center{
  width: 100%;
  padding: 0 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chip-bg-v{
  width: 100%;
  padding-bottom: 48%;
  background: url("../../images/Group 14@2x.png") no-repeat 50% 50%;
  background-size: contain;
  position: relative;
}
.absolute-b-text{
  width: 464px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  color: #4A4A4A;
  font-size: 14px;
  line-height: 19px;
  z-index: 20;
}
//Section v2 END-------------------

//Section v5 start---------------
.img-bd-league-games{
  position: absolute;
  width: 100%;
  top: -180px;
  right: 0;
  background: url("../../images/Group 24@2x.png") no-repeat 0 0;
  background-size: contain;
  padding-bottom: 250%;
  max-width: 380px;
  min-width: 300px;
}
.absolute-b-text{}
.games-icons-wrapper{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 6fr);
  grid-column-gap: 30px;
  padding-top: 24px;
}
.group-icons-b{
  width: 100%;
  padding-top: 10px;
  margin-bottom: 30px;
}
.single-icon-row{
  margin-bottom: 16px;
  display: flex;
}
.icon-container-bg{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  //opacity: 0.2;
  border-radius: 16px;
  &.bg-color-1{
    background-color: rgba(#4A90E2, 0.2);
  }
  &.bg-color-2{
    background-color: rgba(#F5A623, 0.2);
  }
  &.bg-color-3{
    background-color: rgba(#9013FE, 0.2);
  }
  &.bg-color-4{
    background-color: rgba(#FF069E, 0.2);
  }
  &.bg-color-5{
    background-color: rgba(#FF001F, 0.2);
  }
  &.bg-color-6{
    background-color: rgba(#A6A6A6, 0.2);
  }
  &.bg-color-7{
    background-color: rgba(#6FD000, 0.2);
  }
  &.bg-color-8{
    background-color: rgba( #0BDEA1, 0.2);
  }
}
.coming-soon-t{
  width: 100%;
  color: #8D93B8;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 5px;
}
.g-ico-title{
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  padding-left: 16px;
}
.g-list-t{
  margin-bottom: 40px;
}
.img-bd-private-club{
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  background: url("../../images/free@2x.png") no-repeat 0 100%;
  background-size: contain;
  padding-bottom: 100%;
  min-width: 300px;
}
//Section v5 END-----------------

//Divider line OR start-------------------
.divider-line{
  width: 100%;
  padding: 0 168px;
  margin-bottom: 80px;
}
.line-title{
  width: 100%;
  text-align: center;
  position: relative;
  color: #8BCCFF;
  font-size: 64px;
  font-weight: 500;
  text-transform: uppercase;
  &:before{
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 1px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: #8BCCFF;
    z-index: 2;
  }
  > span{
    display: inline-block;
    vertical-align: top;
    padding: 0 42px;
    background: #f6f7ff;
    position: relative;
    z-index: 10;
  }
}
//Divider line OR END---------------------

//Section v6 start---------------
.b-title-container{
  width: 100%;
  text-align: center;
  padding: 176px 20px 0;
  margin-bottom: 48px;
}
//Section v6 END-----------------


//Section v7 start---------------
.mini-bg-wrapper{
  width: 100%;
  border-radius: 150px 0 0 0;
  background: linear-gradient(180deg, #E9EBFF 0%, #F6F7FF 100%);
  min-height: 600px;
  &.right-radius{
    border-radius: 0 150px 0 0;
  }
}
.main-divider-row{
  width: 100%;
  display: grid;
  grid-template-columns: 9fr 360px;
  height: 100%;
  margin-bottom: 40px;
  &.left-img{
    grid-template-columns: 480px 9fr;
  }
}
.solutions-left-col{
  padding: 72px 96px 0 169px;
  &.bigger{
    padding-left: 84px;
  }
}
.p-solutions-text{
  color: #9B9B9B;
  font-size: 24px;
  letter-spacing: 0.67px;
  line-height: 32px;
  margin-bottom: 30px;
}
.img-bg-container-v{
  position: relative;
  width: 100%;
  min-width: 300px;
}
.img-bd-box{
  position: absolute;
  width: 100%;
  top: -180px;
  right: 0;
  background: url("../../images/Group 28@2x.png") no-repeat 0 0;
  background-size: contain;
  padding-bottom: 160%;
  max-width: 380px;
  min-width: 300px;
}
//Become a partner start------
.become-a-partner-container{
  width: 100%;
  padding: 0 88px;
}
.b-ap-bg-container{
  width: 100%;
  position: relative;
  padding: 48px;
  &:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    border-radius: 32px;
    background: linear-gradient(315deg, #5AC4FF 0%, #708DFF 100%);
    z-index: 2;
  }
}
.b-ap-content-box{
  position: relative;
  z-index: 20;
}
.t-container-v{
  width: 100%;
  text-align: center;
  margin-bottom: 55px;
}
.grid-v{
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-column-gap: 114px;
  padding: 0 112px;
}
.b-ap-form-text{
  color: #515151;
  font-size: 14px;
  line-height: 20px;
  padding-top: 24px;
}
.become-form-box{}
.bcm-f-row{
  margin-bottom: 16px;
  &.col-2{
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    grid-column-gap: 16px;
  }
}
.f-item-title{
  color: #4A4A4A;
  font-size: 12px;
  line-height: 16px;
  padding: 0 16px;
  display: block;
  margin-bottom: 8px;
}
.button-container{
  text-align: center;
  padding-top: 16px;
  padding-left: 25px;
  padding-right: 25px;
}
//Become a partner END--------

//Section v7 END---------------

//Section v8 start---------------
.disclaimer-container{
  width: 100%;
  min-height: 205px;
  border-radius: 32px;
  background: radial-gradient(circle, #24425B 0%, #061725 100%);
  padding: 32px 32px 56px;
  position: relative;
  &:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../../images/18+@1.5x.svg") no-repeat 50% 50%;
  }
}
.disclaimer-content{
  width: 100%;
  position: relative;
  z-index: 20;
  text-align: center;
}
.disclaimer-title-v{
  width: 100%;
  font-size: 32px;
  text-align: center;
  margin-bottom: 22px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 36px;
}
.disclaimer-text-v{
  width: 100%;
  display: inline-block;
  vertical-align: center;
  max-width: 624px;
  color: #F6F7FF;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}
//Section v8 END------------------

@media screen and (max-width: 1350px){
  .img-t-b{
    width: 550px;
    background-size: auto 100%;
  }
}
@media screen and (max-width: 1200px){
  .left-t-col{
    padding-left: 60px;
  }
  .solutions-left-col{
    padding-left: 100px;
    padding-right: 40px;
  }
  .become-a-partner-container{
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid-v{
    padding-left: 30px;
    padding-right: 30px;
    grid-column-gap: 80px;
  }
}
//mobile version start--------------------
@media screen and (max-width: 980px){
  .section-b.v-1 {
    margin-bottom: 32px;
  }
  .home-main-section{
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    min-height: inherit;
  }
  .right-img-col{
    padding-left: 48px;
  }
  .img-t-b {
    position: relative;
    top: -56px;
    height: auto;
    width: 100%;
    padding-bottom: 87%;
    background-size: contain;
    background-position: 100% 0;
  }
  .left-t-col{
    margin-top: -76px;
    padding: 0 8px;
  }
  .top-banner-text-b{
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
  }
  .b-ap-form-text{
    padding-top: 0;
    br{
      display: none;
    }
  }
  .download-app-section{
    width: 100%;
    max-width: 100%;
    padding: 20px;
    .mini-b-title{
      font-size: 14px;
      line-height: 19px;
    }
  }
  .app-button-row{
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    grid-column-gap: 7px;
    max-width: 480px;
    margin: 0 auto 16px;
  }
  .app-button-v{
    height: 48px;
    box-shadow: 0 0 0 2px #0AA0FF inset;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  .app-name-v{
    font-size: 14px;
  }
  .app-icon-v{
    width: auto;
    font-size: 22px;
    margin-right: 8px;
    flex: initial;
  }
  .private-club-b{
    padding-top: 8px;
    border-top: 1px solid #E9EAF1;
    > .btn-v{
      &.big{
        min-width: inherit;
        width: 100%;
        max-width: 480px;
      }
    }
  }
  .play-b-title-t{
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 16px;
  }
  .chip-bg-v{
    padding-bottom: 190%;
    background: url("../../images/Group 10@2x.png") no-repeat 50% 50% / contain;
  }
  .b-center{
    padding-left: 8px;
    padding-right: 8px;
  }
  .absolute-b-text{
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .section-b.v-2{
    margin-bottom: 40px;
  }
  .section-b.v-5{
    padding-top: 0;
    margin-bottom: 0;
  }
  .main-divider-row {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    &.left-img {
      flex-direction: column;
    }
  }
  .img-bg-container-v{
    min-width: inherit;
    padding-right: 24px;
    margin-bottom: 40px;
  }
  .solutions-left-col {
    padding: 0 8px;
    &.bigger {
      width: 100%;
      padding: 0 16px;
    }
    + .img-bg-container-v{
      padding-right: 0;
    }
  }
  .img-bd-league-games{
    top: 0;
    position: static;
    background: url("../../images/Group24@2x-m.png") no-repeat 100% 0 / contain;
    min-width: inherit;
    max-width: inherit;
    padding-bottom: 132%;
    margin-bottom: 13px;
  }
  .p-solutions-text{
    font-size: 14px;
    line-height: 19px;
  }
  .title-text-v-4{
    &.g-list-t{
      text-align: center;
    }
  }
  .games-icons-wrapper {
    padding-top: 24px;
    grid-template-columns: 100%;
    grid-column-gap: 0;
    grid-row-gap: 30px;
    padding-left: 36px;
  }
  .img-bd-box{
    position: static;
    min-width: inherit;
    max-width: inherit;
    top: 0;
  }
  .divider-line{
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }
  .line-title {
    font-size: 38px;
  }
  .img-bd-private-club{
    position: static;
  }
  .b-title-container{
    text-align: left;
    padding-top: 40px;
    margin-bottom: 10px;
  }
  .mini-bg-wrapper {
    min-height: inherit;
    border-top-left-radius: 100px;
    &.right-radius {
      border-top-right-radius: 100px;
    }
  }
  .section-b.v-6{
    margin-bottom: 98px;
  }
  .section-b.v-7{
    margin-bottom: 40px;
    > .mini-bg-wrapper{
      background: none;
    }
    .img-bg-container-v{
      padding-left: 24px;
    }
    .solutions-left-col{
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 72px;
    }
  }
  .b-ap-bg-container{
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid-v {
    grid-template-columns: 100%;
    grid-row-gap: 40px;
    grid-column-gap: 0;
    padding-left: 14px;
    padding-right: 14px;
    .button-container{
      padding-right: 0;
      padding-left: 0;
    }
  }
  .bcm-f-row.col-2 {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 0;
    grid-row-gap: 0;
    > .f-item-box {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .t-container-v {
    margin-bottom: 20px;
  }
  .disclaimer-container{
    padding: 32px 24px;
    &:before {
      background-size: 80% auto;
    }
  }
  .section-b.v-8 {
    padding: 0 60px;
  }
  .become-a-partner-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .section-b.v-8 {
    padding-left: 16px;
    padding-right: 16px;
  }
}
//mobile version END----------------------
