.terms-container {
    display: grid;
    grid-template-columns: 4fr 8fr;
    width: 100%;
    padding: 24px 87px 80px;
}
.t-right-col {
    padding-right: 8px;
    margin-top: 24px;
    .nav-container {
        display: flex;
        flex-direction: column;
        margin: 0;
        align-items: unset;
        .nav-link {
            height: unset;
            margin: 12px;
            color: #4A4A4A;
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
            &.active{
                border: 1px solid #0AA0FF;
                border-radius: 23px;
                background-color: #FFFFFF;
                box-shadow: 0 3px 12px -4px rgba(79,2,146,0.2);
                margin: 0 0 0 -12px;
                padding: 12px 24px;
                &:after{
                    display: none;
                }
            }
        }
    }
}
.t-left-col {
    position: relative;
    &:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 172px;
        border-radius: 80px 80px 0 0;
        background: linear-gradient(180deg, #E9EBFF 0%, #F6F7FF 100%);
        z-index: 2;
    }
    .terms-title {
        z-index: 10;
        position: relative;
        text-align: center;
        padding: 48px;
        color: #4A4A4A;
        font-size: 14px;
        line-height: 19px;
    }
    .title-text {
        color: #474A86;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        text-shadow: 0 2px 8px 0 rgba(246,247,255,0.48);
    }
    .terms-content {
        z-index: 10;
        position: relative;
        padding: 0 25px;
        border-left: 1px solid #E9ECFF;
    }
    .section-title {
        color: #4A4A4A;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        margin-top: 32px;
        margin-bottom: 16px;
    }
    .section-text, li {
        color: #4A4A4A;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
        margin: 8px 0;
    }
    li {
        margin-top: 8px;
    }
}
.subsection-title {
    color: #4A4A4A;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

@media screen and (max-width: 600px){
    .terms-container {
        grid-template-columns: 100%;
        padding: 8px;
    }
    .t-right-col {
        display: none;
    }
}