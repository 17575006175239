@font-face {
  font-family: 'CloudSideTech';
  src:  url('fonts/CloudSideTech.eot?xylesx');
  src:  url('fonts/CloudSideTech.eot?xylesx#iefix') format('embedded-opentype'),
    url('fonts/CloudSideTech.ttf?xylesx') format('truetype'),
    url('fonts/CloudSideTech.woff?xylesx') format('woff'),
    url('fonts/CloudSideTech.svg?xylesx#CloudSideTech') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CloudSideTech' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-burger15x:before {
  content: "\e90a";
}
.icon-language-115x:before {
  content: "\e90b";
}
.icon-15x:before {
  content: "\e90c";
}
.icon-phone15x:before {
  content: "\e90d";
}
.icon-home-115x:before {
  content: "\e90e";
}
.icon-minus-315x:before {
  content: "\e907";
}
.icon-plus1x:before {
  content: "\e908";
}
.icon-attach_file15x:before {
  content: "\e906";
}
.icon-android15x:before {
  content: "\e900";
}
.icon-apple15x:before {
  content: "\e901";
}
.icon-youtube15x:before {
  content: "\e902";
}
.icon-insta-115x:before {
  content: "\e903";
}
.icon-insta15x:before {
  content: "\e904";
}
.icon-fb15x:before {
  content: "\e905";
}
