html {
  font-family: 'OpenSans', Arial, Helvetica, sans-serif;
  background-color: #f6f7ff;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  user-select: none;
  color: #070707;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  //min-width: 760px;
}
@media screen and (min-width: 1000px){
  html{
    scrollbar-color: #ffffff #5AC4FF;
    scrollbar-width: thin;
    scroll-behavior: smooth;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #5AC4FF;
    border-radius: 4px;
  }
  ::selection {
    background-color: #5AC4FF;
    color: #ffffff;
  }
}
html, body {
  width: 100%;
  height: 100%;
}
#root{
  width: 100%;
  height: 100%;
}
.App {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-areas:
          "header"
          "content"
          "footer";
  grid-template-rows: auto 10fr auto;
}
.main-content{
  width: 100%;
  grid-area: content;
}
.App-header {
  display: block;
  width: 100%;
  grid-area: header;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 9999;
}
.App-footer{
  display: block;
  width: 100%;
  grid-area: footer;
}
//Titles start-----------------------
.title-box{
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 24px;
  &.bg-line{
    &:before{
      position: absolute;
      content: "";
      display: block;
      left: 0;
      bottom: 0;
      height: 4px;
      border-radius: 3px;
      width: 64px;
      background: linear-gradient(315deg, #5AC4FF 0%, #708DFF 100%);
    }
  }
}
.title-text-v-1{
  color: #019DFF;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
}
.title-text-v-2{
  color: #1E2137;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
}
.title-text-v-3{
  color: #474A86;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
}
.title-text-v-4{
  color: #4A4A4A;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
}
.title-text-v-5{
  color: #474A86;
  font-size: 64px;
  font-weight: 600;
}
.t-shadow{
  text-shadow: 0 2px 8px rgba(246,247,255,0.48);
}
//Titles END-------------------------


//buttons start-----------------------
.btn-v{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  padding: 0 15px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
  font-family: inherit;
  &.bg-color{
    background: linear-gradient(315deg, #5AC4FF 0%, #708DFF 100%);
  }
  &.small{
    height: 34px;
    font-size: 14px;
    min-width: 130px;
    border-radius: 20px;
  }
  &.middle{
    height: 40px;
    min-width: 144px;
    border-radius: 20px;
  }
  &.medium{
    height: 48px;
    width: 208px;
    border-radius: 24px;
  }
  &.big{
    height: 56px;
    min-width: 294px;
    border-radius: 32px;
    font-size: 16px;
  }
  &.trans{
    background: transparent;
    box-shadow: 0 0 0 1px #5AC4FF inset;
  }
  &.disabled{
    background: rgba(216,216,216,0.25);
    pointer-events: none;
    color: #6D6D6D;
  }
}
//buttons END-----------------------
//Inputs start-----------------------
.input-v{
  border: none;
  padding: 0 20px;
  font-family: inherit;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &.medium{
    height: 40px;
    border-radius: 20px;
    background-color: #F6F7FF;
    font-size: 14px;
  }
  &.medium{
    height: 42px;
    border-radius: 21px;
    background-color: #ffffff;
    font-size: 14px;
  }
  &.big{
    height: 48px;
    border-radius: 31px;
    background-color: #FFFFFF;
    font-size: 16px;
  }
  &.full-w{
    width: 100%;
  }
  &::-webkit-input-placeholder {
    color: #C1C1C1;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #C1C1C1;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: #C1C1C1;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: #C1C1C1;
    opacity: 1;
  }
}
.textarea-f-v{
  border: none;
  padding: 15px 20px;
  font-family: inherit;
  box-sizing: border-box;
  height: 160px;
  width: 100%;
  border-radius: 31px;
  background-color: #FFFFFF;
  overflow-y: auto;
  resize: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &.radius-2{
    border-radius: 21px;
  }
}
.with-line{
  box-shadow: none;
  border: 1px solid #D3D3D3;
  transition: border 240ms;
  &:focus{
    border-color: #0AA0FF;
  }
}
//Inputs END-------------------------

//Input file start-------------------
.up-b-holder{
  display: flex;
  align-items: center;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.btn-upload{
  border: 1px dashed #66A9FF;
  color: #6D6D6D;
  background-color: #ffffff;
  padding: 0 22px;
  height: 40px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  border-radius: 20px;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.limit-view{
  color: #CCCCCC;
  font-size: 12px;
  line-height: 16px;
  padding-left: 16px;
}
.btn-upload{
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
}
//Input file END---------------------

//mobile version start--------------------
@media screen and (max-width: 980px) {
  .title-text-v-1{
    font-size: 32px;
    line-height: 38px;
  }
  .title-text-v-3{
    font-size: 32px;
    line-height: 43px;
  }
  .title-text-v-4{
    font-size: 24px;
    line-height: 33px;
  }

  .title-text-v-5{
    font-size: 32px;
    line-height: 43px;
  }
  .title-text-v-2{
    font-size: 28px;
    line-height: 36px;
  }
  .btn-v.big{
    width: 100%;
    min-width: auto;
  }
  .title-text-v-1{
    margin-bottom: 16px;
  }
  .btn-v.small{
    min-width: auto;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
}
//mobile version END----------------------
