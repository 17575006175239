.header-row{
  height: 70px;
  padding: 0 90px;
  background-color: rgba(246,247,255,0.75);
  box-shadow: 0 -2px 0 #0AA0FF inset;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}
.h-left-col{
  display: flex;
  align-items: center;
  height: 100%;
}
.h-right-col{
  display: flex;
  align-items: center;
  height: 100%;
  > .btn-v {
    &.trans{
      color: #070707;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
.logo{
  display: block;
  > img{
    max-height: 43px;
    width: auto;
  }
}
.nav-container{
  display: flex;
  align-items: center;
  margin-left: 87px;
  height: 100%;
}
.nav-link{
  height: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0 20px;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  color: #070707;
  &.active{
    &:after{
      content: "";
      display: block;
      height: 8px;
      width: 31px;
      border-radius: 2px 2px 0 0;
      background-color: #0AA0FF;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      z-index: 2;
    }
  }
}
.lang-sw{
  margin: 0 16px;
  position: relative;
  padding: 0 16px 0 0;
  cursor: pointer;
  &:after{
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #070707 transparent transparent transparent;
    transition: transform 240ms;
  }
  &:hover{
    &:after{
      transition-delay: 180ms;
      transform: scaleY(-1);
    }
    > .lang-drop-container{
      transition: transform 240ms, opacity 360ms;
      transition-delay: 180ms;
      transform: scale(1);
      opacity: 1;
    }
  }
}
.active-lang-v{
  text-transform: uppercase;
}
.lang-drop-container{
  position: absolute;
  right: -20px;
  top: 100%;
  z-index: 55;
  width: 86px;
  padding: 10px 0 0;
  transform: scale(0);
  transform-origin: top;
  transition: transform 180ms, opacity 120ms;
  transition-delay: 100ms;
  opacity: 0;
}
.lang-list-box{
  width: 100%;
  border: 1px solid #A1BDFF;
  border-radius: 8px;
  position: relative;
  padding: 2px 6px;
  background: #ffffff;
  &:before{
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    background: #FFFFFF;
    border-left: 1px solid #A1BDFF;
    border-top: 1px solid #A1BDFF;
    transform: rotate(45deg);
    top: -4px;
    right: 19px;
    z-index: 8;
  }
}
.drop-item-v{
  height: 35px;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #070707;
  &:last-child{
    border-color: transparent;
  }
}
//mobile version start--------------------

//burger navigation start--------
.burger-navigation-container{
  position: absolute;
  right: 20px;
  top: 0;
  width: 40px;
  height: 56px;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 50;
}
.burger-icon-box{
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0AA0FF;
}
.m-nav-open-container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 8;
  padding-top: 56px;
  display: none;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 130%;
    background: #ffffff;
    z-index: 1;
  }
}
.nav-scroll-b-holder{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.scroll-contain-v{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
}
.m-nav-content-b{
  width: 100%;
  padding: 24px 24px 48px;
  box-shadow: 0 2px 24px -2px rgba(0,114,193,0.35);
  position: relative;
  margin: auto 0;
  &:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -48px;
    background: #ffffff;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}
.m-nav-link-b{
  position: relative;
  z-index: 10;
  margin-bottom: 40px;
  > .nav-link{
    width: 100%;
    height: 59px;
    margin: 0;
    font-size: 24px;
    color: #5C5C5C;
    border-top: 1px solid rgba(#0AA0FF, 0.2);
    padding-left: 40px;
    &.active{
      color: #0AA0FF;
      &:after{
        display: none;
      }
    }
    &:first-child{
      border: none;
    }
    > i{
      display: inline-flex;
      height: 100%;
      align-items: center;
      margin-right: 20px;
      width: 28px;
      text-align: center;
      justify-content: center;
    }
  }
}
.language-burger-v{
  width: 100%;
}
.language-burger-v{
  width: 100%;
  box-shadow: 0 0 0 1px #A1BDFF inset;
  border-radius: 16px;
  position: relative;
  z-index: 10;
  padding-left: 40px;
}
.lang-nav-title{
  width: 100%;
  color: #5C5C5C;
  font-weight: normal;
  font-size: 24px;
  padding: 0;
  text-align: left;
  height: 58px;
  display: block;
  align-items: center;
  > i{
    display: inline-flex;
    height: 100%;
    align-items: center;
    margin-right: 20px;
    width: 28px;
    text-align: center;
    justify-content: center;
  }
}
.l-mini-list-b{
  width: 100%;
  padding: 10px 0 0 48px;
  > button{
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    color: #9B9B9B;
    display: block;
    line-height: 20px;
    padding-bottom: 20px;
    text-align: left;
    &.active{
      color: #070707;
    }
  }
}
//burger navigation END----------
@media screen and (max-width: 1200px){
  .header-row{
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 980px){
  .header-row {
    height: 56px;
    padding-left: 24px;
    padding-right: 80px;
    box-shadow: 0 -1px 0 #0AA0FF inset;
  }
  .m-nav-open-container{
    display: block;
    transition: transform 240ms;
    transform: translate3d(120%,0,0);
  }
  .burger-navigation-container{
    display: flex;
    &.active{
      > .icon-burger15x{
        &:before{
          content: "\e908";
          display: block;
          transition: transform 240ms;
          transform: rotate(45deg);
        }
      }
      + .m-nav-open-container{
        transform: translate3d(0,0,0);
      }
    }
  }
  .nav-container{
    display: none;
  }
  .logo{
    > img{
      height: 32px;
    }
  }
  .lang-sw{
    display: none;
  }
  .h-right-col > .btn-v.trans{
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .header-row {
    padding-left: 10px;
    padding-right: 60px;
  }
  .burger-navigation-container{
    right: 10px;
  }
}
//mobile version END----------------------
