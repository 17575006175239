.privacy-container {
    width: 100%;
    padding: 24px 87px 80px;
}
.privacy-content-holder {
    position: relative;    
    &::before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 172px;
        border-radius: 80px 80px 0 0;
        background: linear-gradient(180deg, #E9EBFF 0%, #F6F7FF 100%);
        z-index: 2;
    }
}
.privacy-content {
    padding: 0 24px;
}
.privacy-header {
    padding: 48px 24px;
    z-index: 10;
    position: relative;
    text-align: center;
    color: #4A4A4A;
    font-size: 12px;
    line-height: 17px;
    .title-text {
        color: #474A86;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        text-shadow: 0 2px 8px 0 rgba(246,247,255,0.48);
    }
    .last-updated {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 40px;
    }
}
.section-header {
    color: #4431C6;
    font-size: 32px;
    font-weight: bold;
    line-height: 43px;
    margin-top: 40px;
}
.section-title-text {
    color: #4A4A4A;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
}
.section-text, li {
    color: #4A4A4A;
    font-size: 14px;
    line-height: 19px;
    margin: 8px 0;;
}
li:before {
    content: "";
    background-color: #4431C6;
    font-weight: bold;
    display: inline-block;
    width: 8px;
    margin-right: 16px;
    height: 8px;
    border-radius: 50%;
}
.cookies {
    color: #4A4A4A;
    font-size: 14px;
    line-height: 19px;
}
.subsection-title {
    color: #4A4A4A;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

@media screen and (max-width: 600px){
    .privacy-container {
        padding: 10px;
    }
    .privacy-content {
        padding: 0;
    }
}