.faq-container {
  width: 100%;
  padding: 24px 87px 0;
}
.page-content-holder{
  width: 100%;
  position: relative;
  min-height: 172px;
  &:before{
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 172px;
    border-radius: 80px 80px 0 0;
    background: linear-gradient(180deg, #E9EBFF 0%, #F6F7FF 100%);
    z-index: 2;
  }
}
.faq-content-holder{
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 48px;
}
.p-title-holder{
  width: 100%;
  padding: 0 20px 60px;
  text-align: center;
  margin-bottom: 40px;
}
.p-title-text{
  color: #474A86;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  text-shadow: 0 2px 8px 0 rgba(246,247,255,0.48);
  margin-bottom: 0;
}
.spoiler-holder{
  width: 100%;
  margin-bottom: 80px;
  &:last-child{
    margin-bottom: 30px;
  }
}
.sp-r-title-row{
  width: 100%;
  padding: 0 25px;
  margin-bottom: 24px;
}
.sp-r-title-text{
  color: #4431C6;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
.spoiler-item-container{
  width: 100%;
  border-top: 1px solid rgba(#0AA0FF, 0.2);
  padding: 20px 186px 20px 30px;
  position: relative;
  &:last-child{
    border-bottom: 1px solid rgba(#0AA0FF, 0.2);
  }
  //&:before{
  //  content: "";
  //  position: absolute;
  //  left: 24px;
  //  top: 20px;
  //  display: block;
  //  height: 17px;
  //  width: 17px;
  //  border: 1px solid #AEE2FF;
  //  background-color: #FFFFFF;
  //  border-radius: 50%;
  //}
  &.active{
    > .sp-opened-holder{
      transition: max-height 360ms ease-in;
      max-height: 800px;
    }
    > .icon-plus1x{
      &:before{
        content: "\e907";
      }
    }
  }
}
.sp-opened-holder{
  overflow: hidden;
  transition: max-height 240ms ease-out;
  height: auto;
  max-height: 0;
  transform: translate3d(0,0,0);
}
.open-sp-b{
  position: absolute;
  right: 16px;
  top: 15px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #0AA0FF;
  cursor: pointer;
}
.single-spl-title{
  width: 100%;
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.sp-r-text-v{
  width: 100%;
  color: #4A4A4A;
  font-size: 14px;
  line-height: 19px;
  padding-top: 24px;
}
.sp-r-button-holder{
  padding-top: 24px;
}
//mobile version start-----------------------
@media screen and (max-width: 980px) {
  .faq-container{
    padding-left: 30px;
    padding-right: 30px;
  }
  .spoiler-item-container{
    padding-right: 86px;
  }
}
@media screen and (max-width: 767px) {
  .p-title-holder{
    margin-bottom: 0;
  }
  .sp-r-title-row{
    padding-left: 8px;
  }
  .sp-r-title-text{
    font-size: 24px;
  }
  .faq-container{
    padding-left: 15px;
    padding-right: 15px;
  }
  .faq-content-holder{
    padding-left: 10px;
    padding-right: 10px;
  }
  .spoiler-item-container{
    padding-right: 36px;
    padding-left: 10px;
  }
  .spoiler-item-container:before{
    left: 0;
  }
  .open-sp-b{
    right: 0;
  }
}
//mobile version END-----------------------
