.contact-section-w{
  width: 100%;
  padding: 24px 87px 0;
}
.bg-contact-container{
  padding: 48px;
  width: 100%;
  position: relative;
  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 368px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 80px 80px 0 0;
    background: linear-gradient(180deg, #E9EBFF 0%, #F6F7FF 100%);
    z-index: 5;
  }
}
.uploadImage {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  pointer-events: none;
}
.contact-h-row{
  position: relative;
  text-align: center;
  z-index: 10;
  margin-bottom: 24px;
}
.text-content-v{
  color: #4A4A4A;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding-top: 10px;
}
.contact-f-container{
  width: 100%;
  padding: 0 30px;
  position: relative;
  z-index: 8;
  display: flex;
  display: flex;
  justify-content: center;
}
.form-item-list-w{
  width: 100%;
  max-width: 784px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 9px 4640px -24px rgba(79,2,146,0.2);
  min-height: 532px;
  padding: 50px 80px 0;
}
.c-f-i-row{
  width: 100%;
  margin-bottom: 24px;
  &.col-2-grid{
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    grid-column-gap: 16px;
  }
  &.ctc-button-r{
    display: flex;
    justify-content: space-between;
    .btn-v{
      width: 256px;
    }
  }
}
@media screen and (max-width: 1200px){
  .contact-section-w{
    padding-left: 30px;
    padding-right: 30px;
  }
  .contact-f-container{
      padding-left: 0;
      padding-right: 0;
  }
  .c-f-i-row.ctc-button-r .btn-v{
    width: auto;
  }
}
@media screen and (max-width: 767px){
  .bg-contact-container{
    padding-left: 15px;
    padding-right: 15px;
  }
  .c-f-i-row{
    &.col-2-grid{
      grid-template-columns: 100%;
      grid-column-gap: 0;
      grid-row-gap: 24px;
    }
  }
  .contact-section-w {
    padding-left: 10px;
    padding-right: 10px;
  }
  .form-item-list-w{
    padding-left: 20px;
    padding-right: 20px;
  }
}
//mobile version start--------------------
@media screen and (max-width: 600px){
  .c-f-i-row.ctc-button-r{
    flex-direction: column;
  }
  .up-b-holder{
    margin-bottom: 24px;
  }
  .c-f-i-row.ctc-button-r {
    .btn-v{
      width: 100%;
    }
  }
}
//mobile version END----------------------
