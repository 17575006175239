.footer-row{
  width: 100%;
  min-height: 389px;
  background: #000000;
  color: #E5E5E5;
  padding: 22px 87px 40px;
}
.f-social-row{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  border-bottom: 2px solid #2D2D2D;
}
.social-link-f{
  display: inline-flex;
  height: 56px;
  width: 60px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 0 0 0 1px #2D2D2D inset;
  color: #5F5F5F;
  margin-right: 8px;
  font-size: 24px;
  transition: color 240ms, box-shadow 240ms;
  &:hover{
    color: #ffffff;
    box-shadow: 0 0 0 1px #A1BDFF inset;
  }
}
.app-link-col{
  display: flex;
  align-items: center;
}
.app-download-b{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 0 0 1px #515151 inset;
  height: 54px;
  padding: 8px 10px;
  border-radius: 5px;
  margin-right: 24px;
  cursor: pointer;
  transition: box-shadow 240ms;
  &:hover{
    box-shadow: 0 0 0 1px #A1BDFF inset;
  }
}
.ico-col-v{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 28px;
  margin-right: 12px;
  width: 30px;
  flex: 1;
}
.text-col-v{
  flex: 2;
}
.s-title-v{
  display: block;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
}
.store-name-v{
  display: block;
  white-space: nowrap;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.f-nav-row{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
  min-height: 135px;
}
.f-nav-item{
  margin-bottom: 20px;
}
.f-link-v{
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: #E5E5E5;
}
.subscribe-col{
  display: flex;
  > .input-v{
    width: 314px;
    margin-right: 24px;
  }
}
.copyright-r{
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copyright-text{
  font-size: 14px;
  line-height: 18px;
  color: #565656;
}
//mobile version start--------------------
@media screen and (max-width: 980px) {
  .footer-row {
    padding: 16px;
  }
  .f-social-row{
    flex-direction: column-reverse;
    margin-bottom: 16px;
  }
  .social-col {
    border-top: 1px solid #565656;
    padding-top: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .app-link-col{
    padding-bottom: 17px;
  }
  .f-nav-row {
    min-height: auto;
    flex-direction: column;
    margin-top: 20px;
  }
  .f-nav-col{
    display: flex;
    justify-content: center;
  }
  .f-nav-item{
    margin-left: 24px;
    padding-left: 24px;
    border-left: 1px solid #5C5C5C;
    margin-bottom: 56px;
    &:first-child{
      margin-left: 0;
      padding-left: 0;
      border: none;
    }
  }
  .subscribe-col{
    display: grid;
    grid-template-columns: 10fr auto;
    grid-column-gap: 8px;
    .input-v{
      width: 100%;
    }
  }
  .copyright-r{
    margin-top: 40px;
    border-top: 1px solid #565656;
    padding-top: 16px;
  }
  .copyright-text{
    padding-right: 10px;
  }
}
@media screen and (max-width: 580px){
  .app-link-col{
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    > .btn-v{
      width: 100%;
    }
    > .app-download-b{
      margin-bottom: 20px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .social-link-f{
    font-size: 20px;
  }
  .subscribe-col{
    > .btn-v.middle{
      min-width: 124px;
    }
  }
}
//mobile version END----------------------
